@charset "UTF-8";
*,
body {
  font-family: "Noto sans JP", sans-serif;
  letter-spacing: 0.1em;
  color: #4d4d4d;
  font-weight: 500;
}
@media only screen and (min-width: 821px) {
  *,
  body {
    letter-spacing: 0.14em;
  }
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
  font-feature-settings: "palt";
}
@media only screen and (min-width: 821px) {
  html {
    scroll-padding-top: 130px;
  }
}

body {
  color: #fff;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
dt,
dd,
th,
td,
span,
a {
  line-height: 1.6;
}

img {
  width: 100%;
  vertical-align: bottom;
}

a {
  color: #4d4d4d;
  transition: all 0.4s;
}

.sp {
  display: block !important;
}
@media only screen and (min-width: 821px) {
  .sp {
    display: none !important;
  }
}

.pc {
  display: none !important;
}
@media only screen and (min-width: 821px) {
  .pc {
    display: block !important;
  }
}

.container {
  overflow: hidden;
}

main {
  margin-top: 74px;
}
@media only screen and (min-width: 821px) {
  main {
    margin-top: 0;
  }
}

.c-btn {
  width: 205px;
}
@media only screen and (min-width: 821px) {
  .c-btn {
    width: 250px;
  }
}
.c-btn a {
  display: block;
  width: 100%;
  padding: 12px 0;
  color: #fff;
  background: #7b59a2;
  text-align: center;
  font-size: 15px;
  border-radius: 0 15px 0 15px;
  box-shadow: 7px 7px 10px rgba(123, 89, 162, 0.3);
  border: 1px solid #7b59a2;
  position: relative;
}
@media only screen and (min-width: 821px) {
  .c-btn a {
    padding: 17px;
  }
}
.c-btn a::after {
  content: "";
  display: block;
  width: 15px;
  height: 7px;
  background: url(../img/common/arrow_white.png) no-repeat center top/cover;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.c-btn a:hover {
  background: #fff;
  color: #7b59a2;
}
.c-btn a:hover::after {
  background: url(../img/common/arrow_purple.png) no-repeat center top/cover;
}
.c-btn.white a {
  background: #fff;
  color: #7b59a2;
  border: 3px solid #fff;
  padding: 10px 0;
}
@media only screen and (min-width: 821px) {
  .c-btn.white a {
    padding: 15px 0;
  }
}
.c-btn.white a::after {
  background: url(../img/common/arrow_purple.png) no-repeat center top/cover;
}
.c-btn.white a:hover {
  border: 3px solid #7b59a2;
}

.c-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media only screen and (min-width: 821px) {
  .c-banner {
    position: static;
  }
}
.c-banner .image-link::before {
  padding-top: 29.6%;
}
.c-banner h2 {
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 15px;
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 100%;
}
.c-banner h2::before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url(../img/common/icon_search.png) no-repeat center top/cover;
  margin: 3px 14px 0 0;
}
.c-banner h2::after {
  content: "";
  display: block;
  width: 38px;
  height: 7px;
  background: url(../img/common/arrow_white_long.png) no-repeat center top/cover;
  margin-left: 10px;
}

.c-banner-faq {
  position: relative;
}
.c-banner-faq .image-link::before {
  padding-top: 29.6%;
}
@media only screen and (min-width: 821px) {
  .c-banner-faq .image-link::before {
    padding-top: 23%;
  }
}
.c-banner-faq h2 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 32px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}
.c-banner-faq h2 span {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  margin-left: 20px;
  font-family: "Noto sans JP", sans-serif;
}

.image-link {
  display: block;
  position: relative;
  text-decoration: none;
  overflow: hidden;
}
.image-link::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  /*ここには横幅に対して縦が何%になるかを記述*/
}
.image-link img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.3s;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.image-link:hover img {
  transform: scale(1.1);
}

.sec-ttl {
  font-size: 32px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #7b59a2;
  margin-bottom: 28px;
}
@media only screen and (min-width: 821px) {
  .sec-ttl {
    font-size: 52px;
    margin-bottom: 48px;
  }
}
.sec-ttl.txt-center {
  text-align: center;
}
.sec-ttl span {
  font-family: "Noto sans JP", sans-serif;
  color: #7b59a2;
  font-size: 10px;
  display: block;
}
@media only screen and (min-width: 821px) {
  .sec-ttl span {
    font-size: 14px;
  }
}

.c-femnavi-wrapper {
  margin-bottom: 30px;
}
@media only screen and (min-width: 821px) {
  .c-femnavi-wrapper {
    max-width: 1000px;
    margin: -350px auto 40px;
    display: flex;
    flex-wrap: wrap;
  }
}
.c-femnavi-wrapper.white .content {
  border: none;
  padding-bottom: 0;
  margin-bottom: 30px;
  position: relative;
}
@media only screen and (min-width: 821px) {
  .c-femnavi-wrapper.white .content {
    margin-bottom: 0;
    padding-bottom: 40px;
  }
}
.c-femnavi-wrapper.white .content::after {
  background: url(../img/common/arrow_white_long.png) no-repeat center top/cover;
}
.c-femnavi-wrapper.white .content a .txt .date,
.c-femnavi-wrapper.white .content a .txt .category,
.c-femnavi-wrapper.white .content a .txt .title {
  color: #fff;
}
.c-femnavi-wrapper.white .content a .txt .date span,
.c-femnavi-wrapper.white .content a .txt .category span,
.c-femnavi-wrapper.white .content a .txt .title span {
  color: #fff;
}
.c-femnavi-wrapper .content {
  position: relative;
}
@media only screen and (min-width: 821px) {
  .c-femnavi-wrapper .content {
    margin-bottom: 30px;
    border: none;
    width: 24%;
    margin-right: 1%;
  }
}
@media only screen and (min-width: 821px) {
  .c-femnavi-wrapper .content:nth-child(4n) {
    margin-right: 0;
  }
}
.c-femnavi-wrapper .content:not(.c-femnavi-wrapper .content:last-child) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e2deff;
  position: relative;
}
@media only screen and (min-width: 821px) {
  .c-femnavi-wrapper .content:not(.c-femnavi-wrapper .content:last-child) {
    margin-bottom: 30px;
    padding-bottom: 0;
    border: none;
  }
}
.c-femnavi-wrapper .content::after {
  content: "";
  display: block;
  width: 38px;
  height: 7px;
  background: url(../img/common/arrow_purple_long.png) no-repeat center top/cover;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: -1;
}
@media only screen and (min-width: 821px) {
  .c-femnavi-wrapper .content::after {
    bottom: 10px;
  }
}
.c-femnavi-wrapper .content a {
  display: flex;
  position: relative;
  align-items: flex-start;
  position: relative;
}
@media only screen and (min-width: 821px) {
  .c-femnavi-wrapper .content a {
    padding-bottom: 30px;
    flex-direction: column;
  }
}
.c-femnavi-wrapper .content figure {
  position: relative;
  overflow: hidden;
  width: 120px;
  flex-shrink: 0;
  margin-right: 20px;
}
@media only screen and (min-width: 821px) {
  .c-femnavi-wrapper .content figure {
    width: 100%;
    margin: 0 0 10px 0;
  }
}
.c-femnavi-wrapper .content figure::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.c-femnavi-wrapper .content figure img {
  display: block;
  width: 100%;
  height: 100%;
  inset: 0;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
}
.c-femnavi-wrapper .content .date {
  font-size: 12px;
  margin-bottom: 10px;
  color: #7b59a2;
}
@media only screen and (min-width: 821px) {
  .c-femnavi-wrapper .content .date {
    font-size: 15px;
  }
}
.c-femnavi-wrapper .content .category {
  margin-bottom: 10px;
  font-size: 14px;
}
@media only screen and (min-width: 821px) {
  .c-femnavi-wrapper .content .category {
    font-size: 16px;
  }
}
.c-femnavi-wrapper .content .category span {
  display: inline-block;
  margin-right: 4px;
}
.c-femnavi-wrapper .content .title {
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
  color: #7b59a2;
}
@media only screen and (min-width: 821px) {
  .c-femnavi-wrapper .content .title {
    font-size: 17px;
  }
}

.keyword-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.keyword-wrapper .keyword-content {
  display: inline-flex;
  align-items: center;
  padding: 8px;
  background: #fff;
  font-size: 10px;
  margin: 0 10px 10px 0;
}
@media only screen and (min-width: 821px) {
  .keyword-wrapper .keyword-content {
    font-size: 15px;
    padding: 14px;
  }
}
.keyword-wrapper .keyword-content::before {
  content: "#";
  color: #7b59a2;
  display: block;
  margin: -3px 8px 0 0;
  font-size: 14px;
}

.swiper-button-next {
  right: 40px;
}
.swiper-button-next::after {
  background: url(../img/common/next.png) no-repeat center top/cover;
  width: 40px;
  height: 40px;
  font-size: 0;
}
@media only screen and (min-width: 821px) {
  .swiper-button-next::after {
    width: 60px;
    height: 60px;
  }
}

.swiper-button-prev {
  left: 40px;
}
.swiper-button-prev::after {
  background: url(../img/common/prev.png) no-repeat center top/cover;
  width: 40px;
  height: 40px;
  font-size: 0;
}
@media only screen and (min-width: 821px) {
  .swiper-button-prev::after {
    width: 60px;
    height: 60px;
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px;
  height: 40px;
}
@media only screen and (min-width: 821px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 60px;
    height: 60px;
  }
}

.news-content:first-child a {
  border-top: 1px solid #e2deff;
}
@media only screen and (min-width: 821px) {
  .news-content:first-child a {
    border-top: none;
    border-bottom: 1px solid #e2deff;
  }
}
.news-content a {
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid #e2deff;
}
@media only screen and (min-width: 821px) {
  .news-content a {
    display: flex;
  }
}
.news-content a .date-category {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
@media only screen and (min-width: 821px) {
  .news-content a .date-category {
    font-size: 16px;
    margin: 0 40px 0 0;
  }
}
.news-content a .date-category .date {
  color: #7b59a2;
  margin-right: 10px;
}
@media only screen and (min-width: 821px) {
  .news-content a .date-category .date {
    font-size: 15px;
    margin-right: 40px;
  }
}
.news-content a .title {
  color: #7b59a2;
  text-decoration: underline;
  font-size: 15px;
  font-weight: bold;
}
@media only screen and (min-width: 821px) {
  .news-content a .title {
    font-size: 17px;
    text-decoration: none;
    line-height: 1.4;
    width: 56%;
  }
}

.sidenavi {
  background: rgb(152, 115, 186);
  background: linear-gradient(180deg, rgb(152, 115, 186) 0%, rgb(150, 154, 218) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9873ba", endColorstr="#969ada", GradientType=1);
  padding: 90px 20px 70px;
}
@media only screen and (min-width: 821px) {
  .sidenavi {
    padding: 250px 20px 140px;
  }
}
@media only screen and (min-width: 821px) {
  .sidenavi .inner {
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
    justify-content: space-between;
  }
}
.sidenavi .navi:not(.sidenavi .navi:last-child) {
  margin-bottom: 30px;
}
@media only screen and (min-width: 821px) {
  .sidenavi .navi:not(.sidenavi .navi:last-child) {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 821px) {
  .sidenavi .navi {
    width: 31%;
  }
}
.sidenavi .navi h3 {
  color: #fff;
  font-size: 21px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 18px;
  border-bottom: 1px solid #fff;
}
@media only screen and (min-width: 821px) {
  .sidenavi .navi h3 {
    font-size: 25px;
  }
}
.sidenavi .navi h3 span {
  font-size: 11px;
  font-family: "Noto sans JP", sans-serif;
  display: inline-block;
  margin-left: 10px;
  color: #fff;
}
@media only screen and (min-width: 821px) {
  .sidenavi .navi h3 span {
    font-size: 13px;
    margin-left: 14px;
  }
}
.sidenavi .navi ul li a {
  color: #fff;
  display: flex;
  align-items: center;
  letter-spacing: 0.2em;
}
.sidenavi .navi ul li a::before {
  content: "";
  display: block;
  width: 10px;
  height: 1px;
  background: #fff;
  margin-right: 10px;
}
.sidenavi .navi .keyword-wrapper {
  padding-bottom: 18px;
  margin-bottom: 40px;
  border-bottom: 1px solid #fff;
}
.sidenavi.purple {
  background: 0;
  padding: 0;
}
@media only screen and (min-width: 821px) {
  .sidenavi.purple .navi {
    width: 100%;
  }
}
.sidenavi.purple .navi h3 {
  color: #7b59a2;
  border-bottom: 1px solid #7b59a2;
}
@media only screen and (min-width: 821px) {
  .sidenavi.purple .navi h3 {
    font-size: 17px;
  }
}
.sidenavi.purple .navi h3 span {
  color: #7b59a2;
}
.sidenavi.purple .navi ul li a {
  color: #7b59a2;
}
.sidenavi.purple .navi ul li a::before {
  background: #7b59a2;
}

.search-word {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-word input {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  width: calc(100% - 45px);
  padding: 9px 10px;
  font-size: 14px;
}
.search-word button {
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
}

::-webkit-input-placeholder {
  color: #fff;
  font-size: 14px;
}

::-moz-placeholder {
  color: #fff;
  font-size: 14px;
}

:-ms-input-placeholder {
  color: #fff;
  font-size: 14px;
}

::-ms-input-placeholder {
  color: #fff;
  font-size: 14px;
}

::placeholder {
  color: #fff;
  font-size: 14px;
}

@media only screen and (min-width: 821px) {
  .breadcrumb-wrapper {
    border-bottom: 1px solid #e2deff;
  }
}

.breadcrumb {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1px solid #e2deff;
}
@media only screen and (min-width: 821px) {
  .breadcrumb {
    width: 1000px;
    margin: 0 auto;
    border: none;
  }
}
.breadcrumb span,
.breadcrumb a {
  font-size: 10px;
  font-weight: bold;
  color: #7b59a2;
  line-height: 1.5;
  display: flex;
  align-items: flex-start;
  margin-right: 10px;
}
.breadcrumb span::before,
.breadcrumb a::before {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-bottom: 2px solid #7b59a2;
  border-right: 2px solid #7b59a2;
  transform: rotate(-45deg);
  margin: 5px 10px 0 0;
}
.breadcrumb span br,
.breadcrumb a br {
  display: none !important;
}

.pagenation {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.pagenation .page-numbers {
  color: #4d4d4d;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-size: 3.7333333333vw;
  margin-right: 14px;
}
@media only screen and (min-width: 821px) {
  .pagenation .page-numbers {
    font-size: 14px;
  }
}
.pagenation .page-numbers.current {
  color: #7b59a2;
  border-bottom: 1px solid #7b59a2;
}
.pagenation .page-numbers.prev::before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  transform: rotate(135deg);
  margin-right: 10px;
  border-bottom: 2px solid #7b59a2;
  border-right: 2px solid #7b59a2;
}
.pagenation .page-numbers.next::before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
  margin-left: 10px;
  border-bottom: 2px solid #7b59a2;
  border-right: 2px solid #7b59a2;
}

.c-single-area {
  padding: 0 20px 20px;
}
@media only screen and (min-width: 821px) {
  .c-single-area {
    padding: 0 20px 30px;
  }
}
.c-single-area .wp-block-image .is-style-rounded img,
.c-single-area .wp-block-image.is-style-circle-mask img,
.c-single-area .wp-block-image.is-style-rounded img {
  border-radius: 20px;
}
.c-single-area #ez-toc-container {
  padding: 10px;
  background: rgba(152, 115, 186, 0.1);
  border-radius: inherit;
  border: none;
  width: 100%;
}
@media only screen and (min-width: 821px) {
  .c-single-area #ez-toc-container {
    padding: 30px;
    margin-bottom: 40px;
  }
}
.c-single-area #ez-toc-container a:visited {
  color: #4d4d4d;
}
.c-single-area #ez-toc-container .ez-toc-title-container {
  margin-bottom: 10px !important;
}
.c-single-area .ez-toc-list li {
  display: block !important;
}
.c-single-area .ez-toc-list li::before {
  counter-increment: number;
  content: "" counter(number) ":";
}
.c-single-area .ez-toc-list li a {
  margin-bottom: 10px !important;
  color: #4d4d4d;
}
.c-single-area .ez-toc-list li a::before {
  display: none;
}
.c-single-area .title-area {
  padding-bottom: 10px;
  margin-bottom: 18px;
  border-bottom: 1px solid #e2deff;
}
@media only screen and (min-width: 821px) {
  .c-single-area .title-area {
    margin-bottom: 50px;
  }
}
.c-single-area .date-category {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
}
.c-single-area .date-category .date {
  font-size: 14px;
  color: #7b59a2;
  margin-right: 10px;
}
.c-single-area .date-category .category {
  font-size: 14px;
}
.c-single-area .title {
  font-size: 18px;
  font-weight: bold;
  color: #7b59a2;
  line-height: 1.5;
}
@media only screen and (min-width: 821px) {
  .c-single-area .title {
    font-size: 25px;
  }
}
.c-single-area .thmubnail {
  margin-bottom: 40px;
}
@media only screen and (min-width: 821px) {
  .c-single-area .thmubnail {
    width: 60%;
  }
}
.c-single-area .content-area .wp-block-image {
  margin-bottom: 40px !important;
}
.c-single-area .content-area .wp-block-image figcaption {
  margin-bottom: 0;
}
.c-single-area .content-area .wp-block-image img {
  width: 100%;
}
@media only screen and (min-width: 821px) {
  .c-single-area .content-area .wp-block-image img {
    width: auto;
  }
}
.c-single-area .content-area p {
  font-size: 14px;
  line-height: 1.78;
  margin-bottom: 24px;
}
@media only screen and (min-width: 821px) {
  .c-single-area .content-area p {
    font-size: 16px;
  }
}
.c-single-area .content-area strong {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 24px;
  display: block;
}
@media only screen and (min-width: 821px) {
  .c-single-area .content-area strong {
    font-size: 16px;
  }
}
.c-single-area .content-area figcaption a {
  margin: 0;
}
.c-single-area .content-area a {
  display: inline-block;
  margin-bottom: 24px;
  color: #7b59a2;
  text-decoration: underline;
}
@media only screen and (min-width: 821px) {
  .c-single-area .content-area a {
    font-size: 16px;
  }
}
.c-single-area .content-area h2 {
  line-height: 1.5;
  margin-bottom: 18px;
  padding-bottom: 15px;
  border-bottom: 4px double #7b59a2;
}
@media only screen and (min-width: 821px) {
  .c-single-area .content-area h2 {
    font-size: 20px;
  }
}
.c-single-area .content-area h3 {
  border-bottom: 3px solid #e9e6ff;
  margin-bottom: 18px;
  padding-bottom: 10px;
}
@media only screen and (min-width: 821px) {
  .c-single-area .content-area h3 {
    font-size: 20px;
  }
}
.c-single-area .content-area h4 {
  margin-bottom: 18px;
  padding: 14px 20px;
  background: rgba(150, 154, 218, 0.2);
  background: linear-gradient(90deg, rgba(150, 154, 218, 0.2) 0%, rgba(152, 115, 186, 0.2) 100%);
  border-radius: 12px;
}
@media only screen and (min-width: 821px) {
  .c-single-area .content-area h4 {
    font-size: 20px;
  }
}
.c-single-area .content-area h5 {
  border-top: 1px solid #4d4d4d;
  border-bottom: 1px solid #4d4d4d;
  padding: 10px 0;
  font-size: 14px;
  margin-bottom: 18px;
}
@media only screen and (min-width: 821px) {
  .c-single-area .content-area h5 {
    font-size: 17px;
  }
}
.c-single-area .content-area ul,
.c-single-area .content-area ol {
  background: rgba(152, 115, 186, 0.1);
  padding: 20px;
  margin-bottom: 24px;
  list-style: none;
  counter-reset: number;
}
.c-single-area .content-area ul li,
.c-single-area .content-area ol li {
  font-size: 15px;
  display: flex;
}
@media only screen and (min-width: 821px) {
  .c-single-area .content-area ul li,
  .c-single-area .content-area ol li {
    font-size: 16px;
  }
}
.c-single-area .content-area ul li:not(.c-single-area .content-area ul li:last-child,
.c-single-area .content-area ol li:last-child),
.c-single-area .content-area ol li:not(.c-single-area .content-area ul li:last-child,
.c-single-area .content-area ol li:last-child) {
  margin-bottom: 10px;
}
.c-single-area .content-area ul li::before,
.c-single-area .content-area ol li::before {
  counter-increment: number;
  content: "" counter(number) ":";
}
.c-single-area .keyword-wrapper {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2deff;
}
.c-single-area .keyword-wrapper .keyword-content {
  background: rgba(150, 154, 218, 0.2);
  background: linear-gradient(90deg, rgba(150, 154, 218, 0.2) 0%, rgba(152, 115, 186, 0.2) 100%);
}

.head-area {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 821px) {
  .head-area {
    flex-direction: column-reverse;
    border-bottom: 1px solid #e2deff;
  }
}
.head-area h2 {
  padding: 20px 0;
  background: #e9e6ff;
}
@media only screen and (min-width: 821px) {
  .head-area h2 {
    padding: 150px 0 80px;
    margin-bottom: 0;
  }
}

.prev-next {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 821px) {
  .prev-next {
    width: 100%;
  }
}
.prev-next .c-btn {
  margin: 0 auto;
}
@media only screen and (min-width: 821px) {
  .prev-next .prev {
    display: flex;
    align-items: center;
  }
}
.prev-next .prev span {
  width: 15px;
  height: 7px;
  background: url(../img/common/arrow_purple.png) no-repeat center top/cover;
  transform: scale(-1, 1);
  display: block;
  margin-right: 10px;
}
@media only screen and (min-width: 821px) {
  .prev-next .prev::after {
    content: "前の記事";
    display: block;
  }
}
@media only screen and (min-width: 821px) {
  .prev-next .next {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
}
.prev-next .next span {
  width: 15px;
  height: 7px;
  background: url(../img/common/arrow_purple.png) no-repeat center top/cover;
  display: block;
  margin-left: 10px;
}
@media only screen and (min-width: 821px) {
  .prev-next .next::after {
    content: "次の記事";
    display: block;
  }
}

.links-wrapper .links {
  background: #f3f5ff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 20px;
  margin-bottom: 40px;
}
@media only screen and (min-width: 821px) {
  .links-wrapper .links {
    max-width: 1000px;
    margin: 50px auto;
    padding: 50px;
  }
}
.links-wrapper .links a {
  background: #fff;
  color: #7b59a2;
  width: 48%;
  text-align: center;
  font-size: 10px;
  padding: 16px 0;
  box-shadow: 5px 5px 5px rgba(123, 89, 162, 0.2);
  position: relative;
}
@media only screen and (min-width: 821px) {
  .links-wrapper .links a {
    width: 24%;
    font-size: 12px;
  }
}
.links-wrapper .links a:nth-child(n+3) {
  margin-top: 10px;
}
@media only screen and (min-width: 821px) {
  .links-wrapper .links a:nth-child(n+3) {
    margin-top: 0;
  }
}
.links-wrapper .links a::after {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-bottom: 2px solid #7b59a2;
  border-right: 2px solid #7b59a2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  right: 14px;
}

.under-arrow {
  text-align: center;
  font-size: 18px;
  line-height: 1.6666666667;
}
@media only screen and (min-width: 821px) {
  .under-arrow {
    font-size: 31px;
  }
}
.under-arrow span {
  display: inline-block;
  border-bottom: 1px solid #7b59a2;
  padding-bottom: 14px;
  margin-bottom: 20px;
  color: #7b59a2;
  position: relative;
  font-weight: bold;
}
@media only screen and (min-width: 821px) {
  .under-arrow span {
    padding: 0 20px 24px;
    margin-bottom: 30px;
  }
}
.under-arrow span::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #7b59a2;
  border-right: 1px solid #7b59a2;
  position: absolute;
  transform: translateX(-50%) rotate(45deg);
  left: 50%;
  bottom: -6px;
  background: #fff;
}
@media only screen and (min-width: 821px) {
  .under-arrow span::after {
    width: 20px;
    height: 20px;
    bottom: -11px;
  }
}

.storker-ec {
  position: fixed;
  z-index: 800;
  width: 103px;
  right: 15px;
  bottom: 5%;
}
@media only screen and (min-width: 821px) {
  .storker-ec {
    width: 255px;
    right: 100px;
    bottom: 10%;
  }
}